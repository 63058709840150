// .sponsors {
//   &__your-sponsors {
//     &--list {
//       display: flex;
//       flex-direction: row;
//       justify-content: flex-start;
//       align-items: flex-start;
//       flex-wrap: wrap;
//     }
//   }
// }
.useful-links {
  &__your-useful-links {
    &--list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
}
