@import "./components/Actions/styles.scss";
@import "assets/css/styles.scss";

    .form-element-container {
      margin-bottom: 10px;
  
      .input-icon-container {
        align-items: center;
        padding-bottom: 10px;
        margin-right: 10px;
        font-size: 20px;
        color: $green;
      }
      
      .iframe{
        width:1000px;
        height: 500px;
      }
    }
  
  
