// Override the style provided by the component's library (espace-club-components).
%override-cancel-button-style {
  background-color: $red !important;
  border-color: $red !important;

  &:hover {
    background-color: darken($color: $red, $amount: 25) !important;
    border-color: darken($color: $red, $amount: 25) !important;
  }
}

$gutter: 5px;

.news-form {
  &__actions {
    width: 300px;
    display: flex;
    flex-direction: row;

    & > :first-child {
      margin-right: $gutter;
      @extend %override-cancel-button-style;
    }

    & > :last-child {
      margin-left: $gutter;
    }
  }
}
