@mixin full-size {
  width: 100%;
  height: 100%;
}

@mixin flex(
  $flex-direction: row,
  $justify-content: flex-start,
  $align-items: flex-start
) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

%pointer {
  cursor: pointer;
}
