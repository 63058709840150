.useful-document-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  @include flex(row, center, center);
  justify-content: flex-end;
  align-items: center;

  padding: 10px;

  &--loading {
    color: $blue;
    font-size: 25px;
  }

  &--star {
    position: absolute;
    top: 2px;
    left: 2px;
    color: $yellow;
    font-size: 16px;
  }

  &--action {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: $white;
    font-size: 16px;

    @include flex(row, center, center);

    background-color: $blue;
    border-radius: 20px;
    border: 1px solid $grey-light-2;

    transition: all 0.3s;

    &:hover {
      background-color: $green;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
