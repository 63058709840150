body {
  margin: 0;
  font-family: $font-roboto;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-anton;
  font-weight: 100;
}

/*
 * Fonts
 */
@font-face {
  font-family: "Anton-Regular";
  src: url("../../fonts/Anton-Regular.ttf.eot") format("eot"),
    url("../../fonts/Anton-Regular.ttf.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
