.sponsors {
  &__your-sponsors {
    &--list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
}
