/*
 * Colors
 */
$color-primary: blue;
$color-secondary: green;
$color-danger: red;

$blue: #101d67;
$red: #dd201f;
$green: #56b326;
$white: #fff;
$black: #000;
$grey: #727375;
$grey-light: lighten(#727375, 20%);
$grey-light-2: lighten(#727375, 40%);
$yellow: yellow;

/*
 * Screen breakpoints.
 */
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

/*
 * Fonts.
 */
$font-anton: "Anton-Regular";
$font-roboto: "Roboto", sans-serif;

/*
 * Others.
 */
$header-height: 50px;
$settings-menu-size: 150px;

$dropzone-width: 250px;
$dropzone-height: 150px;
