@import "./components/Actions/styles.scss";

// // The fixed height of .ck-editor__top DOM element.
// $gutter: 38.67px;
$item-w: 200px;
$item-h: 150px;

.sponsor-form {
    .label{
        display: block;
        margin-bottom: 10px;
        color: $green;
        text-transform: uppercase;
    
        &:not(.label-disabled) {
          @extend %pointer;
        }
      }
    .uploadImageContainer{
        @include flex(column, flex-start, flex-start);
        padding-bottom: 20px;
    
        .add-action {
          display: inline-block;
          @extend %pointer;
    
          &[data-color="primary"] {
            color: $blue;
    
            .add-action--icon {
              border-color: $blue;
            }
    
            &:hover {
              .add-action--text {
                border-color: $blue;
              }
            }
          }
    
          &[data-color="danger"] {
            color: $red;
            .add-action--icon {
              border-color: $red;
            }
    
            &:hover {
              .add-action--text {
                border-color: $red;
              }
            }
          }
    
          &--icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            line-height: 25px;
            border: 1px solid $blue;
            border-radius: 100px;
            text-align: center;
            margin: auto;
            margin-right: 5px;
          }
    
          &--icon,
          &--text {
            transition: all 0.3s;
          }
    
          &--text {
            border-bottom: 1px solid transparent;
          }
        }
    .uploadImage{
        @include flex(row, flex-start, flex-start);
        padding-bottom: 10px;
  
        .image {
          width: $item-w;
          height: $item-h;
          margin-top: 5px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
  
        .image-empty {
          width: $item-w;
          height: $item-h;
          margin-top: 5px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
      .separator{
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
  
      .listing {
        @include flex(row, space-between, center);
        flex-wrap: wrap;
        padding-top: 20px;
    
        &--item {
          position: relative;
          width: 150px;
          height: 150px;
          padding-left: 50px;
          margin-bottom: 50px;
          border: 3px solid white;
    
          &:not(:last-child) {
            margin-right: 25px;
          }
    
          &:not([data-clickable="false"]) {
            @extend %pointer;
    
            
          }
        }
    
        &--background {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
  
          &:hover,
            &[data-selected="true"] {
              border: 3px solid;
              border-color: $green;
            }
        }
      }
    }
}
