input,
textarea,
select {
  border: 1px solid $grey-light-2;
  padding: 10px;

  font-size: 20px;
  color: $grey;
  outline: none;

  transition: border-color 0.3s ease;

  &:focus:not([disabled]):not([readonly]):not(.input-not-valid),
  &:hover:not([disabled]):not([readonly]):not(.input-not-valid) {
    border-color: transparent;
    border-bottom: 1px solid $green;
  }

  &.input-not-valid {
    border-color: $red;

    &:hover {
      border-color: transparent;
      border-bottom: 1px solid $red;
    }
  }

  &:disabled,
  &:read-only:not(select) {
    border: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

select {
  background-color: transparent;
  height: 45px;
}

::-webkit-input-placeholder {
  color: $grey-light;
  font-size: 13px;
}

::-moz-placeholder {
  color: $grey-light;
  font-size: 13px;
}

:-ms-input-placeholder {
  color: $grey-light;
  font-size: 13px;
}

::placeholder {
  color: $grey-light;
  font-size: 13px;
}
