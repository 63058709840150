@import 'src/assets/css/abstracts/_variables.scss';

$useful-document-size: 360px;
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);

.display-useful-document {
  position: relative;
  width: $useful-document-size;
  background-color: $white;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
  padding: 5px 10px;
  padding-top: 35px;
  margin-right: 25px;
  margin-bottom: 25px;

  &--title {
    text-transform: uppercase;
    font-family: $font-anton !important;
    margin: 0;
    margin-top: 5px;
    text-align: center;
    color: $green !important;
  }

  &--description {
    color: $blue;
    font-size: 14px;
    padding: 5px;
    overflow: hidden;
    text-align: center;
  }
}
