@import "assets/css/styles.scss";
@import "./components/YourUsefulDocuments/styles.scss";
@import "./components/DisplayOneUsefulDocument/styles.scss";
@import "./components/UsefulDocumentOverlay/styles.scss";
@import "./components/UsefulDocumentsForm/styles.scss";

.useful-documents {
  padding: 0 25px;

  &--add-button {
    display: inline-block;
    color: $blue;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 25px;
    border-bottom: 1px solid transparent;

    .icon {
      margin-right: 5px;
    }

    .text {
    }

    &:hover {
      color: $green;

      border-color: $green;
    }
  }
}
