.news {
  &__your-news {
    &--list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &--add-button {
    display: inline-block;
    color: $blue;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 25px;
    border-bottom: 1px solid transparent;

    .icon {
      margin-right: 5px;
    }

    .text {
    }

    &:hover {
      color: $green;

      border-color: $green;
    }
  }
}
