$news-size: 360px;
$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);

.display-news {
  position: relative;
  width: $news-size;
  margin-right: 25px;
  margin-bottom: 25px;
  background-color: $white;

  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;

  &--image {
    width: $news-size;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--title,
  &--description {
    color: $blue;
    text-align: center;
  }

  &--title {
    text-transform: uppercase;
    font-family: "Anton-Regular" !important;
    margin: 0;
    margin-top: 5px;
    color: $green !important;
  }

  &--description {
    color: $blue;
    font-size: 14px;
    padding: 5px;
    height: 48px;
    max-height: 48px;
    min-height: 48px;
    overflow: hidden;
    text-align: center;
  }
}
